import { imageAdapter } from '@/adapters/constructor/utils/image';

export default ({ data }) => {
  return {
    title: data.title,
    text: data.text,
    subtitle: data.subtitle,
    image: imageAdapter(data.banner, data.banner_mobile),
  };
};
